<script setup lang="ts">
import { computed } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { VBarChart } from '@/modules/shared/components'
import { divide } from '@/modules/shared/utils/math'
import { Money, toNumber, sum } from '@/modules/shared/utils/money'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

const capitalize = (str: string) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

///////////////////////////////////////////////////////////////////////////////

const props = defineProps<{
  currency: string
  hurdle: Money
  hidden_bars?: string[]
  distribution_distributed: Money
  distribution_distributed_capital: Money
  distribution_distributed_profit: Money
  distribution_preferred_return: Money
  distribution_interest_earned: Money
  distribution_distributed_carried_interest: Money
  distribution_distributed_other_fees: Money
  distribution_pending: Money
  distribution_pending_capital: Money
  distribution_pending_profit: Money
  distribution_pending_carried_interest: Money
  distribution_pending_other_fees: Money
  distribution_remaining: Money
  skeleton?: boolean
}>()

const { n, t } = useExtendedI18n()

const is_section_hidden = (section) => {
  if (!props.hidden_bars) return false
  return props.hidden_bars.includes(section)
}

const denominator = computed<Money>(() => {
  const sorted_values = [
    {
      value: props.distribution_distributed,
      number: toNumberOrZero(props.distribution_distributed),
      is_hidden: is_section_hidden('distributed'),
    },
    {
      value: props.distribution_distributed_profit,
      number: toNumberOrZero(props.distribution_distributed_profit),
      is_hidden: is_section_hidden('distributed.profit'),
    },
    {
      value: props.distribution_distributed_capital,
      number: toNumberOrZero(props.distribution_distributed_capital),
      is_hidden: is_section_hidden('distributed.return_of_capital'),
    },
    {
      value: props.distribution_remaining,
      number: toNumberOrZero(props.distribution_remaining),
      is_hidden: is_section_hidden('distributed.hurdle_remaining'),
    },
    {
      value: distributed_total_fees.value,
      number: toNumberOrZero(distributed_total_fees.value),
      is_hidden: is_section_hidden('total_fees'),
    },
    {
      value: props.distribution_distributed_carried_interest,
      number: toNumberOrZero(props.distribution_distributed_carried_interest),
      is_hidden: is_section_hidden('total_fees.carried_interest'),
    },
    {
      value: props.distribution_distributed_other_fees,
      number: toNumberOrZero(props.distribution_distributed_other_fees),
      is_hidden: is_section_hidden('total_fees.other_fees'),
    },
  ]
    .filter((data) => !data.is_hidden)
    .sort((a, b) => b.number - a.number)

  return sorted_values[0].value
})

const toNumberOrZero = (money: Money) => {
  if (!money) return 0.0
  return toNumber(money)
}

const distributed_total_fees = computed(() =>
  sum([props.distribution_distributed_carried_interest, props.distribution_distributed_other_fees]),
)

const ratio = (numerator: Money, denominator: Money, key?) => {
  if (toNumberOrZero(denominator) === 0.0) return 0.0
  return divide(toNumberOrZero(numerator), toNumberOrZero(denominator))
}
</script>

<template>
  <div class="rounded-lg border border-gray-200 bg-white px-0 py-8 dark:border-[#161618] dark:bg-[#1B1B1F]">
    <VBarChart
      :segments="[
        {
          label: capitalize(t('shared.net distributed')),
          value: n(distribution_distributed, 'currency'),
          style: 'bg-[#36326B]',
          ratio: ratio(distribution_distributed, denominator),
          is_hidden: is_section_hidden('distributed'),
          segments: [
            {
              label: capitalize(t('shared.profit')),
              value: n(distribution_distributed_profit, 'currency'),
              style: 'bg-[#36326B]/75',
              ratio: ratio(distribution_distributed_profit, distribution_distributed),
              is_hidden: is_section_hidden('distributed.profit'),
            },
            {
              label: capitalize(t('shared.preferred return')),
              value: n(distribution_preferred_return, 'currency'),
              style: 'bg-[#36326B]/75',
              ratio: ratio(distribution_preferred_return, distribution_distributed),
              is_hidden: is_section_hidden('distributed.preferred_return'),
            },
            {
              label: capitalize(t('shared.interest')),
              value: n(distribution_interest_earned, 'currency'),
              style: 'bg-[#36326B]/75',
              ratio: ratio(distribution_interest_earned, distribution_distributed),
              is_hidden: is_section_hidden('distributed.interest_earned'),
            },
            {
              label: capitalize(t('shared.return of capital')),
              value: n(distribution_distributed_capital, 'currency'),
              style: 'bg-[#36326B]/75',
              ratio: ratio(distribution_distributed_capital, distribution_distributed),
              is_hidden: is_section_hidden('distributed.return_of_capital'),
            },
            {
              label: capitalize(t('shared.hurdle remaining')),
              value: n(distribution_remaining, 'currency'),
              style: 'bg-gray-300',
              ratio: ratio(distribution_remaining, denominator, 'remaining'),
              is_hidden: is_section_hidden('distributed.hurdle_remaining'),
            },
          ],
        },
        {
          label: capitalize(t('shared.total fee', 0)),
          value: n(distributed_total_fees, 'currency'),
          style: 'bg-[#36326B]',
          ratio: ratio(distributed_total_fees, denominator),
          is_hidden: is_section_hidden('total_fees'),
          segments: [
            {
              label: capitalize(t('shared.carried interest', 0)),
              value: n(distribution_distributed_carried_interest, 'currency'),
              style: 'bg-[#8E6080]',
              ratio: ratio(distribution_distributed_carried_interest, distribution_distributed),
              is_hidden: is_section_hidden('total_fees.carried_interest'),
            },
            {
              label: capitalize(t('shared.other fee', 0)),
              value: n(distribution_distributed_other_fees, 'currency'),
              style: 'bg-[#8E6080]/75',
              ratio: ratio(distribution_distributed_other_fees, distribution_distributed),
              is_hidden: is_section_hidden('total_fees.other_fees'),
            },
          ],
        },
        // {
        //   label: capitalize(t('shared.pending')),
        //   value: n(distribution_pending, 'currency'),
        //   style: 'bg-[#408F93]/50',
        //   ratio: ratio(distribution_pending, denominator),
        //   segments: [
        //     {
        //       label: capitalize(t('shared.capital')),
        //       value: n(distribution_pending_capital, 'currency'),
        //       style: 'bg-[#408F93]/50',
        //       ratio: ratio(distribution_pending_capital, distribution_pending),
        //     },
        //     {
        //       label: capitalize(t('shared.profit')),
        //       value: n(distribution_pending_profit, 'currency'),
        //       style: 'bg-[#408F93]/50',
        //       ratio: ratio(distribution_pending_profit, distribution_pending),
        //     },
        //     {
        //       label: capitalize(t('shared.carried interest', 0)),
        //       value: n(distribution_pending_carried_interest, 'currency'),
        //       style: 'bg-gray-700/50',
        //       ratio: ratio(distribution_pending_carried_interest, distribution_pending),
        //     },
        //     {
        //       label: capitalize(t('shared.other fee', 0)),
        //       value: n(distribution_pending_other_fees, 'currency'),
        //       style: 'bg-gray-600/50',
        //       ratio: ratio(distribution_pending_other_fees, distribution_pending),
        //     },
        //   ],
        // },
      ]"
      :skeleton="skeleton"
    />
  </div>
</template>
